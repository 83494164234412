export const drawer = (drawerWidth) => ({
  border: 'none',
  overflowY: 'hidden',
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
  zIndex: 100,
});

export const drawerContent = () => ({
  paddingTop: '15%', 
  display: 'flex', 
  flexDirection: 'column', 
  height: '100%', 
  overflowY: 'hidden'
});

export const radio = () => ({
  color: '#DA291C',
  '&.Mui-checked': {
    color: "#DA291C",
    '& .MuiSvgIcon-root': {
      fill: "#DA291C",
    },
  },
  transform: 'scale(0.7)',
});

export const fontsize = () => ({
  '& .MuiFormControlLabel-label': {
    fontSize:'10px',
    whiteSpace: 'nowrap',
  },
  margin: '-6px -20px',
});

export const fonttype = () => ({
  fontFamily: 'inter',
  fontSize: '14px',
  fontWeight: '300',
  lineHeight: '20px',
  letterSpacing: '0em',
  textAlign: 'left',
});

export const fonttypeHeader = () => ({
  fontFamily: 'inter',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '45px',
  letterSpacing: '0em',
  textAlign: 'left',
  marginLeft: '5px'
});

export const accordionHeader = () => ({
  flexDirection: 'row-reverse',
  minHeight: '35px',
  maxHeight: '35px',
  '&.Mui-expanded': {
    minHeight: '35px',
    maxHeight: '35px',
  },
  '.MuiAccordionSummary-content': {
    margin: '0px',
    padding: '0px',
    '&.Muiexpanded': {
      margin: '0px',
      padding: '0px',
    },
  },
});

export const colorBox = () => ({
  cursor: 'pointer', 
  paddingTop: '2px', 
  display:'flex', 
  justifyContent:'space-between', 
  alignItems:'center', 
  width:'100%'
});

export const textBox = () => ({
  overflow:'hidden', 
  textOverflow:'ellipsis', 
  whiteSpace:'nowrap', 
  marginRight: 2
});

