export const appbar = () => ({
  position:"fixed", 
  background:"none", 
  boxShadow:"none",
  zIndex: 101,
  height: '45px',
  justifyContent: 'center',
});

export const toolbar = () => ({
  justifyContent: "space-between", 
  maxHeight: '10px'
});

export const logo = () => ({
  paddingLeft:'20px', 
  height:'32px',
})