import * as React from 'react';
import { Container, Paper, Typography, useTheme } from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';
import styles from '../../theme.jsx';
import { footer } from './FooterStyles';

function Footer() {
  // Styling
  const theme = useTheme();
  const footerStyle = footer();

  return (
    <Paper sx={{...(footerStyle), background: theme.palette.footer.default}} component="footer" square >
      <Container maxWidth="xl" sx={{...(footerStyle), background: theme.palette.footer.default}}>
        <CopyrightIcon fontSize='extra-small' />
        <Typography sx={{fontFamily:'poppins', fontWeight: '400', fontSize: '14px'}}>
          <span sx={{fontFamily:'poppins'}}>&nbsp;{(new Date().getFullYear())} Bitwise</span>
        </Typography>
      </Container>
    </Paper>
  );
}
export default Footer;